<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="150px">
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="服务项目" prop="serviceName">
            <el-input v-model="form.serviceName" placeholder="请输入服务项目" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="服务分类" prop="serviceType">
            <el-input v-model="form.serviceType" placeholder="请输入服务分类" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="服务内容介绍" prop="serviceContent">
            <el-input v-model="form.serviceContent" placeholder="请输入服务内容介绍" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AddOrEdit',
  data() {
    return {
      titleName: '',
      form: {},
      rules: {
        serviceName: [{ required: true, message: '请输入服务项目', trigger: 'blur' }],
        serviceType: [{ required: true, message: '请输入服务分类', trigger: 'blur' }],
        serviceContent: [{ required: true, message: '请输入服务内容介绍', trigger: 'blur' }],
      },
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['addOrSaveMtkCoreSerive', 'detailMktCoreService']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    getLeaveTime(data) {
      this.form.leaveTime = data
    },
    getFactTime(data) {
      this.form.factTime = data
    },
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加核心服务'
      } else {
        this.titleName = '修改核心服务'
        this.itemData = JSON.parse(this.$route.query.item)
        //这里调用请求编辑时的详情接口渲染form表单数据
        let params = {
          id: this.itemData.id,
        }
        let data = null
        let server = 'detailMktCoreService'
        this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              id: data.id,
              serviceName: data.serviceName,
              serviceType: data.serviceType,
              serviceContent: data.serviceContent,
            }
          }
        })
      }
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let source = 21
          let params = {
            ...this.form,
            compId: this.$route.params.compId,
            source,
          }
          let server = 'addOrSaveMtkCoreSerive'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
